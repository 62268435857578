@font-face {
  font-family: equiplight;
  src: url("Hoftype - Equip-Light.77c1f5b5.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: equipthin;
  src: url("Hoftype - Equip-Thin.c79192f0.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

/*# sourceMappingURL=index.05a5801a.css.map */
